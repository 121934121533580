@import "./variable.scss";
@mixin common-label {
  font-size: 12px;
  padding: 4px 8px;
  color: $theme-color-2;
  border: 1px solid $theme-color-3;
  border-radius: 8px;
  display: inline-block;
  .label-icon {
    margin: 0 0 0 6px;
    font-size: 12px;
    cursor: pointer;
    vertical-align: middle;
    line-height: 11px;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
    display: inline-grid;
    background-size: contain;
    color: $theme-color-1;
  }
}
// Simple label with icon
.label-with-icon {
  .label {
    height: 32px;
    border-radius: 20px;
    border: 1px solid $theme-color-7;
    background: $theme-color-15;
    color: $theme-color-7;
    letter-spacing: -0.14px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.2;
    .label-icon {
      color: $theme-color-7;
      margin: 0 4px;
      cursor: pointer;
      background-image: url("../../../assets/icons/svg/close.svg");
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      display: inline-grid;
      background-size: contain;
    }
  }
}
.simple-label {
  display: inline-block;
  .label-name {
    background-color: $theme-color-15;
    font-size: 12px;
    padding: 4px 8px;
    color: $theme-color-2;
    border: 1px solid $theme-color-3;
    border-radius: 8px;
  }
}
.filter-label {
  display: inline-block;
  .label {
    @include common-label;
    background-color: $theme-color-15;
    .label-icon {
      background-image: url("../../../assets/icons/svg/close_circled.svg");
      background-repeat: no-repeat;
    }
  }
}
.filter-label-blue {
  display: inline-block;
  .label {
    @include common-label;
    background-color: $theme-color-4;
    color: $theme-color-25;
    .close-icon {
      height: 14px;
      background-image: url("../../../assets/icons/svg/placeholder.svg");
      background-repeat: no-repeat;
    }
  }
}
