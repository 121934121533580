.font-ubuntu-light {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .font-ubuntu-regular {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .font-ubuntu-medium {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .font-ubuntu-bold {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .font-ubuntu-light-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .font-ubuntu-regular-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .font-ubuntu-medium-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .font-ubuntu-bold-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  