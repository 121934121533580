@import "./variable.scss";
@mixin common-font {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin icon-common {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-content: center;
  column-gap: 4px;
}
@mixin svg-common {
  background-repeat: no-repeat;
  color: $theme-color-2;
  height: 16px;
  width: 16px;
  margin-top: 2px;
}
$type-primary: (
    primary-lg,
    48px,
    100%,
    24px,
    $theme-color-2,
    $theme-color-25,
    16px
  ),
  (primary-sm, 36px, 100%, 24px, $theme-color-2, $theme-color-25, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-primary
{
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 0;
    cursor: pointer;
    @include common-font;
    &:hover {
      background-color: $theme-color-24;
    }
    &:active {
      box-shadow: 0px 1px 6px 0px $btn-primary-3;
    }
    &:focus {
      background-color: $theme-color-4;
      box-shadow: 0px 1px 6px 0px $btn-primary-3;
      outline: 1px solid $theme-color-4;
      outline-offset: 1px;
    }
  }
}
$type-primary-disabled: (
    btn-primary-lg,
    48px,
    100%,
    24px,
    $theme-color-23,
    $theme-color-21,
    16px
  ),
  (btn-primary-sm, 36px, 100%, 24px, $theme-color-23, $theme-color-21, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-primary-disabled
{
  button[class*="#{$name}"]:disabled {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 0;
    cursor: not-allowed;
    @include common-font;
  }
}
$type-secondary: (
    secondary-lg,
    48px,
    100%,
    24px,
    transparent,
    $theme-color-2,
    16px
  ),
  (secondary-sm, 36px, 100%, 24px, transparent, $theme-color-2, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-secondary
{
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 1px solid $theme-color-2;
    cursor: pointer;
    @include common-font;
    &:hover {
      background-color: $theme-color-23;
    }
    &:active {
      box-shadow: 0px 1px 6px 0px $btn-primary-3;
    }
    &:focus {
      border: 1px solid $theme-color-4;
      box-shadow: 0px 1px 6px 0px $btn-primary-3;
      outline: 1px solid $theme-color-4;
      outline-offset: 1px;
    }
  }
}
$type-secondary-disabled: (
    sd-lg,
    48px,
    100%,
    24px,
    transparent,
    $theme-color-21,
    16px
  ),
  (sd-sm, 36px, 100%, 24px, transparent, $theme-color-21, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-secondary-disabled
{
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 1px solid $theme-color-22;
    cursor: not-allowed;
    @include common-font;
  }
}
$type-icon: (icon-lg, 48px, 100%, 24px, $theme-color-25, $theme-color-2, 16px),
  (icon-sm, 36px, 100%, 24px, $theme-color-25, $theme-color-2, 14px);
@each $name, $height, $width, $padding, $bg-color, $color, $size in $type-icon {
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 1px solid $theme-color-2;
    cursor: pointer;
    @include icon-common;
    .plus-icon {
      background-image: url("../../../assets//icons/svg/plus.svg");
      margin-right: 0px;
      @include svg-common;
    }
    .icon-with-text {
      margin-top: 1px;
    }
    i {
      height: 100%;
      &::before {
        vertical-align: middle;
      }
    }
    .p-button-label {
      font-weight: 500;
    }
  }
}
$type-icon-disabled: (icon-lg, $theme-color-23, $theme-color-21),
  (icon-sm, $theme-color-23, $theme-color-21);
@each $name, $bg-color, $color in $type-icon-disabled {
  button[class*="#{$name}"]:disabled {
    cursor: not-allowed;
    background-color: $bg-color;
    color: $color;
    border: 0;
  }
}
$type-icon-edit: (
    edit-icon-lg,
    48px,
    100%,
    24px,
    $theme-color-25,
    $theme-color-2,
    16px
  ),
  (edit-icon-sm, 32px, 100%, 12px, $theme-color-25, $theme-color-2, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-icon-edit
{
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 4px;
    border: 1px solid $theme-color-2;
    cursor: pointer;
    @include icon-common;
    @include common-font;
    .pencil-icon {
      background-image: url("../../../assets//icons/svg/edit.svg");
      @include svg-common;
    }
    .icon-with-text {
      margin-top: 1px;
    }
  }
}
$type-red: (red-lg, 48px, 100%, 24px, $theme-color-1, $theme-color-25, 16px),
  (red-sm, 36px, 100%, 24px, $theme-color-1, $theme-color-25, 14px);
@each $name, $height, $width, $padding, $bg-color, $color, $size in $type-red {
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 0;
    cursor: pointer;
    @include common-font;
  }
}
$type-red-disabled: (
    red-lg,
    48px,
    100%,
    24px,
    $theme-color-1,
    $theme-color-25,
    16px
  ),
  (red-sm, 36px, 100%, 24px, $theme-color-1, $theme-color-25, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-red-disabled
{
  button[class*="#{$name}"]:disabled {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $red-disabled-color-1;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 0;
    cursor: not-allowed;
    @include common-font;
  }
}
$type-cancel-icon: (
    cancel-lg,
    48px,
    100%,
    24px,
    $theme-color-25,
    $theme-color-1,
    16px
  ),
  (cancel-sm, 36px, 100%, 24px, $theme-color-25, $theme-color-1, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-cancel-icon
{
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 1px solid $theme-color-1;
    cursor: pointer;
    @include common-font;
    .pi {
      margin-right: 10px;
      font-size: 14px;
      vertical-align: middle;
    }
  }
}
$type-primary: (
    primary-lg,
    48px,
    100%,
    24px,
    $theme-color-2,
    $theme-color-25,
    16px
  ),
  (primary-sm, 36px, 100%, 24px, $theme-color-2, $theme-color-25, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-primary
{
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 0;
    cursor: pointer;
    @include common-font;
    &:hover {
      background-color: $theme-color-24;
    }
    &:active {
      box-shadow: 0px 1px 6px 0px $btn-primary-3;
    }
    &:focus {
      background-color: $theme-color-4;
      box-shadow: 0px 1px 6px 0px $btn-primary-3;
      outline: 1px solid $theme-color-4;
      outline-offset: 1px;
    }
  }
}
$type-secondary-disabled-btn: (
    btn-secondary-lg,
    48px,
    100%,
    24px,
    $theme-color-23,
    $theme-color-21,
    16px
  ),
  (btn-secondary-sm, 36px, 100%, 24px, $theme-color-23, $theme-color-21, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-secondary-disabled-btn
{
  button[class*="#{$name}"]:disabled {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 0;
    cursor: not-allowed;
    @include common-font;
  }
}
button.pi-spinner:disabled {
  animation: none;
  font-size: 0;
  cursor: not-allowed;
  background-color: $theme-color-24;
  color: $theme-color-25;
  &::before {
    display: inline-block;
    font-family: "primeicons", sans-serif;
    font-size: 2rem;
    animation: fa-spin 2s infinite linear;
  }
}
$type-download-icon: (
    download-lg,
    48px,
    fit-content,
    24px,
    transparent,
    $theme-color-2,
    16px
  ),
  (download-sm, 36px, 100%, 24px, $theme-color-2, $theme-color-25, 14px);
@each $name, $height, $width, $padding, $bg-color, $color,
  $size in $type-download-icon
{
  .btn-#{$name} {
    height: $height;
    width: $width;
    padding: 0px $padding;
    background-color: $bg-color;
    color: $color;
    font-size: $size;
    border-radius: 8px;
    border: 1px solid $theme-color-2;
    cursor: pointer;
    @include common-font;
    i#btn-icon {
      display: inline-block;
      background-image: url("../../../assets/icons/svg/download-icon.svg");
      background-size: contain;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
    }
    &:disabled {
      background-color: $theme-color-23;
      color: $theme-color-21;
      border: 0;
      cursor: not-allowed;
    }
  }
}
button:focus {
  box-shadow: none !important;
}
