@import "./variable.scss";
@mixin common-color {
  border-color: $theme-color-2;
  box-shadow: none;
}
.p-checkbox {
  .p-checkbox-box {
    border-radius: 3px;
    &.p-highlight {
      border-color: $theme-color-2;
      background: $theme-color-2;
    }
    &.p-focus {
      @include common-color;
    }
    &:hover {
      @include common-color;
    }
  }
  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-focus {
        @include common-color;
      }
      &:hover {
        @include common-color;
      }
    }
  }
}
.p-checkbox-custom-label {
  color: $theme-color-2;
  margin-left: 4px;
}
