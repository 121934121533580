//Theme color
$theme-color-1: #dd040a;
$theme-color-2: #07073a;
$theme-color-3: #141e58;
$theme-color-4: #0070c0;
$theme-color-5: #00b0f0;
$theme-color-6: #8bf54d;
$theme-color-7: #23206f;
$theme-color-8: #0f2f7f;
$theme-color-9: #004782;
$theme-color-10: #33d4ff;
$theme-color-11: #77d142;
$theme-color-12: #5867bf;
$theme-color-13: #1647a8;
$theme-color-14: #008bff;
$theme-color-15: #e1f4f9;
$theme-color-16: #dbffc7;
$theme-color-17: #fc0;
$theme-color-18: #6ee5ba;
$theme-color-19: #222328;
$theme-color-20: #383a42;
$theme-color-21: #7c8087;
$theme-color-22: #a9aeb5;
$theme-color-23: #d9d9d6;
$theme-color-24: #00002b;
$theme-color-25: #ffffff;
$theme-color-26: #576062;
$theme-color-27: #dcdcdc;
// Button color
$btn-primary-3: rgba(0, 0, 0, 0.12);
//Icon text color
$icon-text-1: #191c1d;
// Inputbox border color
$inputbox-border: #bfc8ca;
//Grid color
$grid-color-1: #e9f2f5;
$grid-color-2: #cee0e6;
$grid-color-3: #eceeee;
$grid-color-4: #f3f4f6;
// Calender color
$calender-color-1: #eff6ff;
$calender-color-2: #1d4ed8;
$calender-color-3: #5a5a5f;
//Status color
$status-color-1: #294f29;
$status-color-2: #ef4348;
$status-color-3: rgba(239, 67, 72, 0.1);
//dropdown color
$dropdown-color-1: #d9eaf1;
$dropdown-color-2: #27596f;
//completion status color
$status-color-4: #f2f4f4;
// tab-filter color
$tab-filter-color-1: rgba(0, 0, 0, 0.1);
//page title
$page-title: #000000;
// report card color
$report-card-1: #f7faf9;
// red disable color;
$red-disabled-color-1: #da444975;
