$pair: (sm, 16px), (md, 24px), (lg, 36px), (xl, 48px), (xxl, 64px);
@each $name, $width in $pair {
  .icon-#{$name} {
    width: #{$width};
    height: #{$width};
    background-image: url("../../../assets/icons/svg/filter.svg");
    background-repeat: no-repeat;
    display: inline-grid;
    background-size: contain;
  }
}
