@import "./variable.scss";
@mixin common-font {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.input-box {
  width: 100%;
  height: 45px;
  padding: 16px;
  color: $icon-text-1;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid $inputbox-border;
  background-color: $theme-color-25;
  outline: none;
  box-shadow: none;
  @include common-font;
}
.input-box-with-icon {
  position: relative;
  .input-box-search {
    @extend .input-box;
    padding: 16px 36px 16px 16px;
  }
  .search-icon {
    background-image: url("../../../assets//icons//svg/search.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    color: $icon-text-1;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}
input:disabled {
  @extend .input-box;
  cursor: not-allowed;
  background-color: $theme-color-23;
}
.input-box.inputbox-error {
  @extend .input-box;
  border: 1px solid $theme-color-1;
}
