@import "./variable.scss";
.radio-button {
  margin: 0.5rem;
  position: relative;
}
.radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.radio-button input[type="radio"] + .radio-label {
  margin-right: 24px;
  display: flex;
  align-items: center;
}
.radio-button input[type="radio"] + .radio-label:before {
  content: "";
  border-radius: 100%;
  border: 1px solid darken($inputbox-border, 25%);
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio-button input[type="radio"]:checked + .radio-label:before {
  background-color: $theme-color-2;
  box-shadow: inset 0 0 0 4px $theme-color-25;
}
.radio-button input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: $theme-color-2;
}
.radio-button input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px $inputbox-border;
  border-color: darken($inputbox-border, 25%);
  background: darken($inputbox-border, 25%);
}
.radio-button input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}
