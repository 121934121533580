@import "./variable.scss";
.report {
  .p-element {
    .p-card {
      border-radius: 16px;
      -webkit-box-shadow: 0px 0px 8px 1px rgba(206, 214, 211, 0.5);
      -moz-box-shadow: 0px 0px 8px 1px rgba(206, 214, 211, 0.5);
      box-shadow: 0px 0px 8px 1px rgba(206, 214, 211, 0.5);
      background-color: $report-card-1;
      .p-card-body {
        padding: 40px;
        .p-card-content {
          padding: 0px;
        }
      }
    }
  }
}
.summary {
  .p-element {
    .p-card {
      .p-card-body {
        padding: 24px;
        .p-card-content {
          padding: 0px;
        }
      }
    }
  }
}
