@import "primeicons/primeicons.css";
$statuses: (
  "received": (
    color: $theme-color-9,
    bg: $theme-color-15,
  ),
  "in-process": (
    color: $theme-color-9,
    bg: $theme-color-15,
  ),
  "despatched": (
    color: $theme-color-9,
    bg: $theme-color-15,
  ),
  "in-transit": (
    color: $theme-color-9,
    bg: $theme-color-15,
  ),
  "out-for-delivery": (
    color: $theme-color-9,
    bg: $theme-color-15,
  ),
  "available-for-pickup": (
    color: $status-color-1,
    bg: $theme-color-16,
  ),
  "delivered": (
    color: $status-color-1,
    bg: $theme-color-16,
  ),
  "on-hold": (
    color: $status-color-2,
    bg: $status-color-3,
  ),
  "cancelled": (
    color: $status-color-2,
    bg: $status-color-3,
  ),
  "delivery-failed": (
    color: $status-color-2,
    bg: $status-color-3,
  ),
);
@mixin status-badge($color, $bg) {
  color: $color;
  background-color: $bg;
}
.status-badge {
  display: inline-flex;
  align-items: center;
  border-radius: 40px;
  height: 36px;
  padding: 0px 12px;
  justify-content: center;
  gap: 6px;
  flex-shrink: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  .status-icon {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
@each $status, $values in $statuses {
  .status-badge.#{$status} {
    @include status-badge(map-get($values, color), map-get($values, bg));
  }
}
.circle-check {
  background-image: url("../../../assets/icons/svg/circle-check-1.svg");
  width: 20px;
  height: 20px;
}
.circle-check-2 {
  background-image: url("../../../assets/icons/svg/circle-check-2.svg");
  width: 20px;
  height: 20px;
}
.circle-exclaimation {
  background-image: url("../../../assets/icons/svg/circle-exaclaimation.svg");
  width: 20px;
  height: 20px;
}
.circle-cancel {
  background-image: url("../../../assets/icons/svg/circle-cancel.svg");
  width: 20px;
  height: 20px;
}
.inprocess-icon {
  background-image: url("../../../assets/icons/svg/inprocess.svg");
  width: 20px;
  height: 20px;
}
