.dialog-with-header {
  .p-dialog {
    box-shadow: 0px 1px 6px 0px $btn-primary-3;
    .p-dialog-header {
      padding: 40px 24px 0px 24px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    .p-dialog-content {
      padding: 40px 24px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
}
.dialog-without-header {
  .p-dialog {
    box-shadow: 0px 1px 6px 0px $btn-primary-3;
    .p-dialog-content {
      padding: 40px 24px;
      border-radius: 16px;
    }
  }
}
