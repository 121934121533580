/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
//@import "primeflex/primeflex.scss"; --commented by anshul jain as we are already using primeflex.css in index.html
@import "../src/app/shared/styles/button.scss";
@import "../src/app/shared/styles/font-style.scss";
@import "../src/app/shared/styles/input-box.scss";
@import "../src/app/shared/styles/snackbar.scss";
@import "../src/app/shared/styles/labels.scss";
@import "../src/app/shared/styles/radio-button.scss";
@import "../src/app/shared/styles/status-badge.scss";
@import "../src/app/shared/styles/dropdown.scss";
@import "../src/app/shared/styles/completion-status.scss";
@import "../src/app/shared/styles/check-box.scss";
@import "../src/app/shared/styles/checkmark-design.scss";
@import "../src/app/shared/styles/icon.scss";
@import "../src/app/shared/styles/popup.scss";
@import "../src/app/shared/styles/card.scss";
@import "../src/app/shared/styles/variable.scss";
* {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
  background-color: #f7faf9;
  &.no-scroll {
    overflow: hidden;
  }
}
//below code will hide caret symbol when we open usr info popup
div.user-info-popup.p-overlaypanel {
  border-radius: 1rem;
  cursor: initial;
  &::before,
  &::after {
    content: unset;
    bottom: 0%;
  }
  .p-overlaypanel-content {
    padding: 0.5rem;
  }
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(191, 200, 202, 1);
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(191, 200, 202, 1);
}
.p-calendar {
  width: 100%;
  input {
    cursor: pointer;
    border: 1px solid $inputbox-border;
    height: 45px;
    box-shadow: none;
    & + .p-datepicker-icon {
      top: calc(50% - 12.5px);
      margin-top: 0px;
      &::before {
        background-image: url("./assets/icons/svg/calendar-days.svg");
        height: 25px;
        width: 25px;
        content: "";
        cursor: pointer;
        background-repeat: no-repeat;
      }
      & > svg {
        display: none;
      }
    }
  }
}
.p-dialog {
  border-radius: 1rem;
  overflow: hidden;
}
[pbadge] span.p-badge {
  background-color: #f1f5f9;
  color: $theme-color-2;
}
