@import "./variable.scss";
@mixin common-font {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.result-container {
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  color: $theme-color-2;
  .col-title {
    margin-bottom: 8px;
    font-weight: 500;
  }
  .result-row {
    display: flex;
    justify-content: flex-start;
    .result-item {
      display: flex;
      text-align: center;
    }
    .checked {
      text-align: center;
    }
  }
}
.check-icon {
  background-image: url("../../../assets/icons/svg/check.svg");
  height: 24px;
  width: 24px;
}
