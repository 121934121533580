@import "./variable.scss";
.massage-container {
  border: none;
  border-radius: 16px;
  width: 95vw;
}
.custom-icon {
  background-size: contain;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
  margin-top: 15px;
}
.p-toast {
  .p-toast-message {
    .p-toast-message-content {
      .p-toast-summary {
        line-height: 24px;
        font-weight: 500;
      }
      .p-toast-detail {
        font-size: 14px;
        line-height: 20px;
      }
    }
    //success snackbar custom scss
    &.p-toast-message-success {
      @extend .massage-container;
      color: $theme-color-2;
      background-color: $theme-color-11;
      .p-toast-icon-close {
        color: $theme-color-2;
      }
    }
    //error snackbar custom scss
    &.p-toast-message-error {
      @extend .massage-container;
      color: $theme-color-25;
      background-color: $theme-color-1;
      .p-toast-icon-close {
        color: $theme-color-25;
      }
    }
  }
}
.pi.pi-custom-success-icon {
  @extend .custom-icon;
  background: url("../../../assets/icons/circle-check.png") no-repeat center
    center;
}
.pi.pi-custom-error-icon {
  @extend .custom-icon;
  background: url("../../../assets/icons/circle-alert.png") no-repeat center
    center;
}
p-messages div.p-messages {
  .p-message.p-message-error {
    border-color: $theme-color-1;
    color: $theme-color-1;
  }
}
