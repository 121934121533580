@import "./variable.scss";
// Single selection dropdown popup box
.p-dropdown-panel {
  border-radius: 16px;
  margin-top: 5px;
  .p-dropdown-items {
    padding: 0px;
    .p-dropdown-item {
      border-radius: 6px;
      color: $theme-color-2;
      margin: 2px;
      span {
        font-size: 14px;
        font-weight: 500;
      }
      &.p-highlight.p-focus {
        color: $theme-color-2;
        background-color: $theme-color-15;
      }
    }
    .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
      background-color: $theme-color-15;
    }
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      background-color: $theme-color-15;
    }
  }
  .p-dropdown-items-wrapper {
    overflow: hidden;
    max-height: unset !important;
    padding: 8px;
  }
}
// Single selection dropdown input box
p-dropdown {
  .p-dropdown {
    border-radius: 8px;
    border: 1px solid $inputbox-border;
    width: 100%;
    height: 45px;
    .p-dropdown-label.p-inputtext {
      color: $theme-color-2;
    }
    .p-dropdown-label.p-inputtext.p-placeholder {
      color: $theme-color-21;
    }
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: $inputbox-border;
  }
  .p-dropdown-trigger {
    .p-dropdown-trigger-icon {
      margin-top: 0px;
      background-image: url("../../../assets/icons/svg/chevron.down.svg");
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      content: "";
      cursor: pointer;
      > path {
        display: none;
      }
    }
  }
}
// Multiselect dropdown input box
.p-multiselect {
  min-width: 100%;
  width: 200px;
  max-width: 100px;
  border-radius: 8px;
  border: 1px solid $inputbox-border;
  height: 45px;
  .p-multiselect-label-container {
    .p-multiselect-label {
      padding: 12px 8px 12px 8px;
      .p-multiselect-token {
        color: $dropdown-color-2;
        background: $dropdown-color-1;
        border-radius: 8px;
        padding: 4px;
        margin-right: 4px;
        height: 20px;
        .p-multiselect-token-label {
          font-size: 14px;
          line-height: 20px;
        }
        .p-icon-wrapper {
          background-image: url("../../../assets/icons/svg/close_circled.svg");
          height: 12px;
          width: 12px;
          margin-left: 4px;
          background-repeat: no-repeat;
          content: "";
          cursor: pointer;
          svg {
            display: none;
          }
        }
      }
      .p-placeholder {
        color: $theme-color-21;
      }
      .p-multiselect-token-icon {
        margin-left: 4px;
      }
    }
  }
  .p-multiselect-trigger {
    cursor: pointer;
    padding-right: 8px;
    width: 32px;
    .p-multiselect-trigger-icon {
      margin-top: 0px;
      background-image: url("../../../assets/icons/svg/chevron.down.svg");
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
      content: "";
      cursor: pointer;
      > path {
        display: none;
      }
    }
  }
  &:not(.p-disabled):hover {
    border-color: $inputbox-border;
  }
  &:hover {
    border-color: $inputbox-border;
  }
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}
// Multiselect dropdown pop-up box
.p-multiselect-panel {
  border-radius: 16px;
  margin-top: 5px;
  .p-multiselect-items-wrapper {
    .p-multiselect-items {
      padding: 0px;
      .p-multiselect-item {
        border-radius: 8px;
        color: $theme-color-2;
        margin: 6px;
      }
      .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
        background: $theme-color-15;
      }
      .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
        background: $theme-color-15;
      }
    }
  }
}
// Treeselect dropdown
.p-tree {
  .p-tree-container {
    .p-treenode {
      .p-treenode-content {
        .p-tree-toggler {
          order: 2;
          margin-left: auto;
          margin-right: 0px;
        }
        &.p-treenode-selectable:not(.p-highlight):hover {
          color: $theme-color-2;
          background: $theme-color-15;
        }
        &.p-highlight {
          color: $theme-color-2;
          background: $theme-color-25;
          &:hover {
            color: $theme-color-2;
            background: $theme-color-15;
          }
        }
      }
      &:focus > .p-treenode-content {
        outline: none;
        outline-offset: 0;
        box-shadow: none;
      }
    }
  }
  color: $theme-color-2;
  padding: 0.5rem 0 0.5rem 1.5rem;
}
.p-treeselect {
  margin-left: 10px !important;
  border: 1px solid $inputbox-border;
  .p-treeselect-items-wrapper {
    margin-top: 8px;
    margin-right: 0px;
  }
  &:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  &:not(.p-disabled):hover {
    border-color: $inputbox-border;
  }
}
.p-inputwrapper-filled {
  .p-treeselect.p-treeselect-chip {
    .p-treeselect-label {
      color: $theme-color-26;
      padding: 0.75rem 0.75rem;
    }
  }
}
p-treeselect {
  div.client-warehouse-dd {
    p-tree {
      div.p-tree-selectable {
        padding-left: 1rem;
        ul li[aria-level="1"] div.p-treenode-selectable {
          padding-left: 0.5rem !important;
        }
      }
    }
  }
}
