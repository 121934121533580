.completion-status {
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px;
  margin: 10px 8px;
  &.complete {
    color: $theme-color-11;
    gap: 8px;
    .icon {
      background-color: white;
      display: flex;
      width: 32.096px;
      height: 32.096px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 32.096px;
      border: 3px solid $theme-color-11;
      &:before {
        content: "";
        position: absolute;
        width: 5px;
        height: 12px;
        border: solid $theme-color-11;
        border-width: 0 3px 3px 0;
        transform: translate(-5%, -5%) rotate(45deg);
      }
    }
  }
  &.excess {
    color: $theme-color-1;
    gap: 8px;
    .icon {
      background-color: white;
      border: 3px solid $theme-color-1;
      border-radius: 32.096px;
      width: 32.096px;
      height: 32.096px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      &:before {
        content: "\0021";
        font-size: 20px;
      }
    }
  }
  &.in-progress {
    color: $theme-color-5;
    gap: 8px;
    .icon {
      position: relative;
      width: 32.096px;
      height: 32.096px;
      border-radius: 32.096px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      background: conic-gradient(
        $theme-color-5 0% calc(var(--percentage) * 1%),
        $status-color-4 calc(var(--percentage) * 1%) 100%
      );
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        flex-shrink: 0;
        background-color: white;
        border-radius: 32.096px;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.empty-ring {
    gap: 8px;
    .icon {
      background-color: white;
      border: 3px solid $status-color-4;
      border-radius: 32.096px;
      width: 32.096px;
      height: 32.096px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      font-weight: 500;
    }
  }
}
